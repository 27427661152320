export async function sendDataToServer(selected, type) {
    const url = 'https://4ditsolutions.co.za/code/react-app-email.php';
    const data = {
        selectedTier: selected,
        name: type.name,
        email: type.email,
        message: type.message
    };

    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });
        return response.json();
    } catch (error) {
        console.error('Failed to send data to server:', error);
        return { status: 'error', message: 'Network error or server is unavailable' };
    }
}
