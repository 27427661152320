import React from "react";

const SVG = ({ selectedTier, type }) => {

    let colours;

if (selectedTier === 'Responsive') {
    colours = {
        'Monochromatic Shades of Red': ['Red'],
        'Monochromatic Shades of Blue': ['Blue'],
        'Monochromatic Shades of Yellow': ['Yellow'],
        'Monochromatic Shades of Green': ['Green'],
        'Monochromatic Shades of Orange': ['Orange'],
        'Monochromatic Shades of Purple': ['Purple'],
        'Monochromatic Shades of Pink': ['Pink'],
        'Monochromatic Shades of Cyan': ['Cyan'],
        'Monochromatic Shades of Brown': ['Brown'],
        'Monochromatic Shades of Gold': ['Gold'],
        'Monochromatic Shades of Black/White': [(type.Shading === 'Light' ? '#000' : "#fff")],
        'Monochromatic Shades of Grey': ['Grey'],
        'Complimentary Shades of Green & Purple': ['Green', 'Purple'],
        'Complimentary Shades of Yellow & Blue': ['Yellow', 'Blue'],
        'Complimentary Shades of Red & Cyan': ['Red', 'Cyan']
    }
} else if (selectedTier === 'Dynamic') {
    colours = {
        'Grey': [
            '#808080',
            (type.Scheme && (type.Scheme === 'Solid' ? '#808080' : type.Scheme === 'Monochromatic' ? '#c9c9c9' : type.Scheme === 'Triadic' ? '#808080' : type.Scheme === 'Complimentary' ? '#fff' : type.Scheme === 'Analogous' && '#808080')),
            (type.Scheme && (type.Scheme === 'Solid' ? '#808080' : type.Scheme === 'Monochromatic' ? '#5b5b5b' : type.Scheme === 'Triadic' ? '#808080' : type.Scheme === 'Complimentary' ? '#000' : type.Scheme === 'Analogous' && '#808080'))
        ],
        'Red': [
            '#FF0000',
            (type.Scheme && (type.Scheme === 'Solid' ? '#ff0000' : type.Scheme === 'Monochromatic' ? '#ff1e1e' : type.Scheme === 'Triadic' ? '#00ff00' : type.Scheme === 'Complimentary' ? '#ccff00' : type.Scheme === 'Analogous' && '#ff00ff')),
            (type.Scheme && (type.Scheme === 'Solid' ? '#ff0000' : type.Scheme === 'Monochromatic' ? '#d20000' : type.Scheme === 'Triadic' ? '#0000ff' : type.Scheme === 'Complimentary' ? '#0066ff' : type.Scheme === 'Analogous' && '#ffff00'))
        ],
        'Blue': [
                '#0000ff',
                (type.Scheme && (type.Scheme === 'Solid' ? '#0000ff' : type.Scheme === 'Monochromatic' ? '#1e1eff' : type.Scheme === 'Triadic' ? '#ff0000' : type.Scheme === 'Complimentary' ? '#ff00cc' : type.Scheme === 'Analogous' && '#00ffff')),
                (type.Scheme && (type.Scheme === 'Solid' ? '#0000ff' : type.Scheme === 'Monochromatic' ? '#0000b8' : type.Scheme === 'Triadic' ? '#00ff00' : type.Scheme === 'Complimentary' ? '#66ff00' : type.Scheme === 'Analogous' && '#ff00ff'))
            ],
        'Yellow': [
            '#ffff00',
            (type.Scheme && (type.Scheme === 'Solid' ? '#ffff00' : type.Scheme === 'Monochromatic' ? '#ffff52' : type.Scheme === 'Triadic' ? '#00ffff' : type.Scheme === 'Complimentary' ? '#ff0000' : type.Scheme === 'Analogous' && '#ff4500')),
            (type.Scheme && (type.Scheme === 'Solid' ? '#ffff00' : type.Scheme === 'Monochromatic' ? '#d2d200' : type.Scheme === 'Triadic' ? '#ff00ff' : type.Scheme === 'Complimentary' ? '#00ff00' : type.Scheme === 'Analogous' && '#00fe00'))
        ],
        'Orange': [
            '#ffa500',
            (type.Scheme && (type.Scheme === 'Solid' ? '#ffa500' : type.Scheme === 'Monochromatic' ? '#1e1eff' : type.Scheme === 'Triadic' ? '#00ffa5' : type.Scheme === 'Complimentary' ? '#27ff00' : type.Scheme === 'Analogous' && '#ff005a')),
            (type.Scheme && (type.Scheme === 'Solid' ? '#ffa500' : type.Scheme === 'Monochromatic' ? '#d28a00' : type.Scheme === 'Triadic' ? '#a500ff' : type.Scheme === 'Complimentary' ? '#3f00ff' : type.Scheme === 'Analogous' && '#5aff00'))
        ],
        'Green': [
            '#008000',
            (type.Scheme && (type.Scheme === 'Solid' ? '#008000' : type.Scheme === 'Monochromatic' ? '#94ff94' : type.Scheme === 'Triadic' ? '#000080' : type.Scheme === 'Complimentary' ? '#006680' : type.Scheme === 'Analogous' && '#808000')),
            (type.Scheme && (type.Scheme === 'Solid' ? '#008000' : type.Scheme === 'Monochromatic' ? '#00b800' : type.Scheme === 'Triadic' ? '#800000' : type.Scheme === 'Complimentary' ? '#800033' : type.Scheme === 'Analogous' && '#008080'))
        ],
        'Purple': [
            '#800080',
            (type.Scheme && (type.Scheme === 'Solid' ? '#800080' : type.Scheme === 'Monochromatic' ? '#ff1eff' : type.Scheme === 'Triadic' ? '#808000' : type.Scheme === 'Complimentary' ? '#801a00' : type.Scheme === 'Analogous' && '#000080')),
            (type.Scheme && (type.Scheme === 'Solid' ? '#800080' : type.Scheme === 'Monochromatic' ? '#a200a2' : type.Scheme === 'Triadic' ? '#008080' : type.Scheme === 'Complimentary' ? '#00804d' : type.Scheme === 'Analogous' && '#800000'))
        ],
        'Pink': [
            '#FFC0CB',
            (type.Scheme && (type.Scheme === 'Solid' ? '#FFC0CB' : type.Scheme === 'Monochromatic' ? '#ff94a8' : type.Scheme === 'Triadic' ? '#cbffc0' : type.Scheme === 'Complimentary' ? '#fdffc0' : type.Scheme === 'Analogous' && '#f4c0ff')),
            (type.Scheme && (type.Scheme === 'Solid' ? '#FFC0CB' : type.Scheme === 'Monochromatic' ? '#ff5274' : type.Scheme === 'Triadic' ? '#c0cbff' : type.Scheme === 'Complimentary' ? '#c0e4ff' : type.Scheme === 'Analogous' && '#fff4c0'))
        ],
        'Brown': [
            '#964B00',
            (type.Scheme && (type.Scheme === 'Solid' ? '#964B00' : type.Scheme === 'Monochromatic' ? '#d26900' : type.Scheme === 'Triadic' ? '#00964b' : type.Scheme === 'Complimentary' ? '#2d9600' : type.Scheme === 'Analogous' && '#96004b')),
            (type.Scheme && (type.Scheme === 'Solid' ? '#964B00' : type.Scheme === 'Monochromatic' ? '#4d2600' : type.Scheme === 'Triadic' ? '#4b0096' : type.Scheme === 'Complimentary' ? '#0f0096' : type.Scheme === 'Analogous' && '#4b9600'))
        ],
        'Cyan': [
            '#00FFFF',
            (type.Scheme && (type.Scheme === 'Solid' ? '#00FFFF' : type.Scheme === 'Monochromatic' ? '#94ffff' : type.Scheme === 'Triadic' ? '#ff00ff' : type.Scheme === 'Complimentary' ? '#3300ff' : type.Scheme === 'Analogous' && '#00ff00')),
            (type.Scheme && (type.Scheme === 'Solid' ? '#00FFFF' : type.Scheme === 'Monochromatic' ? '#00b8b8' : type.Scheme === 'Triadic' ? '#ffff00' : type.Scheme === 'Complimentary' ? '#ff9900' : type.Scheme === 'Analogous' && '#0000ff'))
        ],
        'Magenta': [
            '#FF00FF',
            (type.Scheme && (type.Scheme === 'Solid' ? '#FF00FF' : type.Scheme === 'Monochromatic' ? '#ff52ff' : type.Scheme === 'Triadic' ? '#ffff00' : type.Scheme === 'Complimentary' ? '#ff3300' : type.Scheme === 'Analogous' && '#0000ff')),
            (type.Scheme && (type.Scheme === 'Solid' ? '#FF00FF' : type.Scheme === 'Monochromatic' ? '#b800b8' : type.Scheme === 'Triadic' ? '#00ffff' : type.Scheme === 'Complimentary' ? '#00ff99' : type.Scheme === 'Analogous' && '#ff0000'))
        ],
        'Maroon': [
            '#800000',
            (type.Scheme && (type.Scheme === 'Solid' ? '#800000' : type.Scheme === 'Monochromatic' ? '#ff9494' : type.Scheme === 'Triadic' ? '#008000' : type.Scheme === 'Complimentary' ? '#668000' : type.Scheme === 'Analogous' && '#800080')),
            (type.Scheme && (type.Scheme === 'Solid' ? '#800000' : type.Scheme === 'Monochromatic' ? '#b80000' : type.Scheme === 'Triadic' ? '#000080' : type.Scheme === 'Complimentary' ? '#003380' : type.Scheme === 'Analogous' && '#808000'))
        ],
        'Teal': [
            '#008080',
            (type.Scheme && (type.Scheme === 'Solid' ? '#008080' : type.Scheme === 'Monochromatic' ? '#94ffff' : type.Scheme === 'Triadic' ? '#800080' : type.Scheme === 'Complimentary' ? '#1a0080' : type.Scheme === 'Analogous' && '#008000')),
            (type.Scheme && (type.Scheme === 'Solid' ? '#008080' : type.Scheme === 'Monochromatic' ? '#00b8b8' : type.Scheme === 'Triadic' ? '#808000' : type.Scheme === 'Complimentary' ? '#804d00' : type.Scheme === 'Analogous' && '#000080'))
        ],
        'Beige': [
            '#F5F5DC',
            (type.Scheme && (type.Scheme === 'Solid' ? '#F5F5DC' : type.Scheme === 'Monochromatic' ? '#e6e6ac' : type.Scheme === 'Triadic' ? '#dcf5f5' : type.Scheme === 'Complimentary' ? '#dcf5e1' : type.Scheme === 'Analogous' && '#f5dcdc')),
            (type.Scheme && (type.Scheme === 'Solid' ? '#F5F5DC' : type.Scheme === 'Monochromatic' ? '#8e8e29' : type.Scheme === 'Triadic' ? '#f5dcf5' : type.Scheme === 'Complimentary' ? '#ebdcf5' : type.Scheme === 'Analogous' && '#dcf5dc'))
        ],
        'Peach': [
            '#FFE5B4',
            (type.Scheme && (type.Scheme === 'Solid' ? '#FFE5B4' : type.Scheme === 'Monochromatic' ? '#ffda94' : type.Scheme === 'Triadic' ? '#b4ffe5' : type.Scheme === 'Complimentary' ? '#bfffb4' : type.Scheme === 'Analogous' && '#ffb4ce')),
            (type.Scheme && (type.Scheme === 'Solid' ? '#FFE5B4' : type.Scheme === 'Monochromatic' ? '#b87800' : type.Scheme === 'Triadic' ? '#e5b4ff' : type.Scheme === 'Complimentary' ? '#c7b4ff' : type.Scheme === 'Analogous' && '#ceffb4'))
        ],
        'Olive': [
            '#808000',
            (type.Scheme && (type.Scheme === 'Solid' ? '#808000' : type.Scheme === 'Monochromatic' ? '#ffff94' : type.Scheme === 'Triadic' ? '#008080' : type.Scheme === 'Complimentary' ? '#00801a' : type.Scheme === 'Analogous' && '#800000')),
            (type.Scheme && (type.Scheme === 'Solid' ? '#808000' : type.Scheme === 'Monochromatic' ? '#b8b800' : type.Scheme === 'Triadic' ? '#800080' : type.Scheme === 'Complimentary' ? '#4d0080' : type.Scheme === 'Analogous' && '#008000'))
        ],
        'Gold': [
            '#FFD700',
            (type.Scheme && (type.Scheme === 'Solid' ? '#FFD700' : type.Scheme === 'Monochromatic' ? '#ffee94' : type.Scheme === 'Triadic' ? '#00ffd7' : type.Scheme === 'Complimentary' ? '#00ff0b' : type.Scheme === 'Analogous' && '#ff0028')),
            (type.Scheme && (type.Scheme === 'Solid' ? '#FFD700' : type.Scheme === 'Monochromatic' ? '#b89b00' : type.Scheme === 'Triadic' ? '#d700ff' : type.Scheme === 'Complimentary' ? '#7100ff' : type.Scheme === 'Analogous' && '#28ff00'))
        ],
    }
}

    function layoutType(type, colours){
        if (type.layout === 'Z-pattern layout') {
            return <path d="M20,80 L600,80 L20,320 L600,320" stroke={selectedTier === 'Static' && type.colour ? type.colour : type.colour ? colours[type.colour][0] : '#fff' } fill="none" strokeWidth={10}/>;
        } else if (type.layout === 'F-pattern layout') {
            return <path d="M600,80 L20,80 L20,320 M20,180 L400,180" stroke={selectedTier === 'Static' && type.colour ? type.colour : type.colour ? colours[type.colour][0] : '#fff'} fill="none" strokeWidth={10}/>;
        } else if (type.layout === 'Single Column') {
            return (
                <>
                    <rect x="64" y="60" width="80%" height="10%" fill={selectedTier === 'Static' && type.colour ? type.colour : type.colour ? colours[type.colour][0] : '#808080' } />
                    <rect x="64" y="110" width="80%" height="10%" fill={selectedTier === 'Static' && type.colour ? type.colour : type.colour ? colours[type.colour][0] : '#808080' } />
                    <rect x="64" y="160" width="80%" height="10%" fill={selectedTier === 'Static' && type.colour ? type.colour : type.colour ? colours[type.colour][0] : '#808080' } />
                    <rect x="64" y="210" width="80%" height="10%" fill={selectedTier === 'Static' && type.colour ? type.colour : type.colour ? colours[type.colour][0] : '#808080' } />
                    <rect x="64" y="260" width="80%" height="10%" fill={selectedTier === 'Static' && type.colour ? type.colour : type.colour ? colours[type.colour][0] : '#808080' } />
                </>
            );
        } else if (type.layout === 'Cards') {
            return (
                <>
                    <rect x="45" y="80" width="20%" height="40%" fill={selectedTier === 'Static' && type.colour ? type.colour : type.colour ? colours[type.colour][0] : '#808080' } />
                    <rect x="185" y="80" width="20%" height="40%" fill={selectedTier === 'Static' && type.colour ? type.colour : type.colour ? colours[type.colour][0] : '#808080' } />
                    <rect x="325" y="80" width="20%" height="40%" fill={selectedTier === 'Static' && type.colour ? type.colour : type.colour ? colours[type.colour][0] : '#808080' } />
                    <rect x="465" y="80" width="20%" height="40%" fill={selectedTier === 'Static' && type.colour ? type.colour : type.colour ? colours[type.colour][0] : '#808080' } />
                </>
            );
        } else if (type.layout === 'Full Screen Image') {

            return (
                <>
                    <defs>
                        <pattern id="patt2" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                            <circle cx="10" cy="10" r="10" fill={selectedTier === 'Static' && type.colour ? type.colour : type.colour ? colours[type.colour][0] : "#121212"} opacity='0.3'/>
                        </pattern>
                    </defs>
                    <rect x="0" y="0" width="100%" height="100%" fill="url(#patt2)" />
                </>
            );
        } else if (type.layout === 'Split Screen') {
            return (
                <>
                    <path d="M40,80 L600,80 L600,320 L40,320, L40,75 M318,80 L318,320" stroke={type.colour ? colours[type.colour][0] : '#fff' } fill="none" strokeWidth={10}/>
                    <rect x="82" y="92" width="30%" height="60%" fill={selectedTier === 'Static' && type.colour ? type.colour : type.colour ? colours[type.colour][0] : '#808080' }/>
                    <rect x="360" y="92" width="30%" height="60%" fill={selectedTier === 'Static' && type.colour ? type.colour : type.colour ? colours[type.colour][0] : '#808080' }/>
                </>
            );
        }
    }

  return (
    
    selectedTier === 'Dynamic' ? (

    <svg version="1.1"
        width="640" height="360"
        xmlns="http://www.w3.org/2000/svg"
    >

        <rect width="100%" height="100%" fill={type.Shading === 'Light' ? "#fff" : "#000"} />

        {type.layout && layoutType(type, colours)}

        {type.navbarType === 'Seperated' &&
            <>
                <rect x="5%" y="10" width="10%" height="30" fill="#fff" />
                <rect x="25%" y="10" width="10%" height="30" fill="#fff" />
                <rect x="45%" y="10" width="10%" height="30" fill="#fff" />
                <rect x="65%" y="10" width="10%" height="30" fill="#fff" />
                <rect x="85%" y="10" width="10%" height="30" fill="#fff" />

                <text x="7%" y="35" fontSize="30" fill="gray">L</text>
                <text x="27%" y="35" fontSize="30" fill="gray">O</text>
                <text x="47%" y="35" fontSize="30" fill="gray">R</text>
                <text x="67%" y="35" fontSize="30" fill="gray">E</text>
                <text x="87%" y="35" fontSize="30" fill="gray">M</text>
             </>
        }

        {type.navbarType === 'Solid' &&
            <>
                <rect x="0" y="0" width="100%" height="50" fill="#fff" />
                <text x="7%" y="35" fontSize="30" fill="gray">L</text>
                <text x="27%" y="35" fontSize="30" fill="gray">O</text>
                <text x="47%" y="35" fontSize="30" fill="gray">R</text>
                <text x="67%" y="35" fontSize="30" fill="gray">E</text>
                <text x="87%" y="35" fontSize="30" fill="gray">M</text>
            </>
        }

        {type.navbarType === 'Sidebar' &&
        <>
            <rect x="0" y="0" width="50" height="100%" fill="#fff" />
            <text y="10%" x="17" fontSize="30" fill="gray">L</text>
            <text y="30%" x="12" fontSize="30" fill="gray">O</text>
            <text y="50%" x="15" fontSize="30" fill="gray">R</text>
            <text y="70%" x="15" fontSize="30" fill="gray">E</text>
            <text y="90%" x="10" fontSize="30" fill="gray">M</text>
        </>

        }

        {type.navbarType === 'Footer' &&
        <>
            <rect x="0" y="310" width="100%" height="50" fill="#fff" />
            <text x="7%" y="345" fontSize="30" fill="gray">L</text>
            <text x="27%" y="345" fontSize="30" fill="gray">O</text>
            <text x="47%" y="345" fontSize="30" fill="gray">R</text>
            <text x="67%" y="345" fontSize="30" fill="gray">E</text>
            <text x="87%" y="345" fontSize="30" fill="gray">M</text>
        </>

        }

        {type.navbarType === 'Button' &&
            <>
                <rect x="20" y="15" width="50" height="10" fill="#fff" />
                <rect x="20" y="35" width="50" height="10" fill="#fff" />
                <rect x="20" y="55" width="50" height="10" fill="#fff" />
            </>
        }

        {type.Scheme &&
            <>
                <rect x="35%" y="40%" width="30%" height="10%" rx="5%" fill={type.Shading === 'Light' ? '#e2e2e2' : '#121212' } />
                <circle r="10" cx="40%" cy="45%" fill={type.Scheme && type.colour ? colours[type.colour][1] : '#808080'} />
                <circle r="10" cx="50%" cy="45%" fill={type.Scheme && type.colour ? colours[type.colour][0] : '#808080'} />
                <circle r="10" cx="60%" cy="45%" fill={type.Scheme && type.colour ? colours[type.colour][2] : '#808080'} />
            </>
        }

    </svg>

    ) : (

    selectedTier === 'Responsive' ? (
        <svg
            version="1.1"
            width="640" height="360"
            xmlns="http://www.w3.org/2000/svg"        
        >
            <rect width="100%" height="100%" fill={type.colour ? type.colour.includes('Complimentary Shades of') === true && colours[type.colour][1] : (type.Shading === 'Light' ? "#fff" : "#000")} />

            <defs>
                <linearGradient id="gradient-black-to-white" x1="0%" y1="0%" x2="100%" y2="0%">
                <stop offset="0%" style={{stopColor: type.Shading === 'Light' && type.navbarType === 'Background Hue' ? '#fff' : '#000',stopOpacity:1}} />
                <stop offset="100%" style={{stopColor: type.colour ? colours[type.colour][0] : type.Shading === 'Light' && type.navbarType === 'Background Hue' ? '#000' : '#fff',stopOpacity:1}} />
                </linearGradient>
            </defs>

            <defs>
                <pattern id="patt1" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                    <circle cx="10" cy="10" r="10" fill={type.colour ? colours[type.colour][0] : "#121212"} />
                </pattern>
            </defs>

            {type.navbarType === 'Background colour' && <rect x="0" y="0" width="100%" height="50" fill={type.colour ? colours[type.colour][0] : "#fff"} />}
            {type.navbarType === 'Background Hue' && <rect x="0" y="0" width="100%" height="50" fill="url(#gradient-black-to-white)" />}
            {type.navbarType === 'No Background' && <rect x="0" y="0" width="100%" height="50" fill="none" />}
            {type.navbarType === 'Background Image' && <rect x="0" y="0" width="100%" height="50" fill="url(#patt1)" />}
            {!type.navbarType && <rect x="0" y="0" width="100%" height="50" fill={type.colour ? colours[type.colour][0] : colours['Monochromatic Shades of Black/White'][0]}/>}
            

            <text x="7%" y="35" fontSize="30" fill="gray">L</text>
            <text x="27%" y="35" fontSize="30" fill="gray">O</text>
            <text x="47%" y="35" fontSize="30" fill="gray">R</text>
            <text x="67%" y="35" fontSize="30" fill="gray">E</text>
            <text x="87%" y="35" fontSize="30" fill="gray">M</text>

            {type.layout && layoutType(type, colours)}

        </svg>

    ) : (
        selectedTier === 'Static' &&
        <svg
            version="1.1"
            width="640" height="360"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect width="100%" height="100%" fill={type.Shading ? type.Shading : '#000'} />
            {type.navbarType !== 'No Background' && <rect x="0" y="0" width="100%" height="50" fill={type.colour? type.colour : '#fff'} /> }

            <text x="7%" y="35" fontSize="30" fill={type.navbarType === 'No Background' ? (type.colour ? type.colour : "gray") : "gray"}>L</text>
            <text x="27%" y="35" fontSize="30" fill={type.navbarType === 'No Background' ? (type.colour ? type.colour : "gray") : "gray"}>O</text>
            <text x="47%" y="35" fontSize="30" fill={type.navbarType === 'No Background' ? (type.colour ? type.colour : "gray") : "gray"}>R</text>
            <text x="67%" y="35" fontSize="30" fill={type.navbarType === 'No Background' ? (type.colour ? type.colour : "gray") : "gray"}>E</text>
            <text x="87%" y="35" fontSize="30" fill={type.navbarType === 'No Background' ? (type.colour ? type.colour : "gray") : "gray"}>M</text>

            {type.layout && layoutType(type, colours)}

        </svg>
    ))

  );
}

export default SVG;
