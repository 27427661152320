import React, { useState, useEffect } from 'react';

const SubList = ({ subtext, choice, resetDependency }) => {
  const [inputFilled, setInputFilled] = useState(Array(subtext.length).fill(false));

  useEffect(() => {
    setInputFilled(Array(subtext.length).fill(false));
  }, [resetDependency, subtext.length]);

  const handleChoice = (name, label) => {
    choice(name, label);
  };

  const handleInputFilled = (type, label, name, subIndex, event) => {
    let newInputFilled = [...inputFilled];

    switch (type) {
      case 'checkbox':
        newInputFilled[subIndex] = !newInputFilled[subIndex];
        handleChoice(name, newInputFilled[subIndex] ? label : "");
        break;
      case 'text':
      case 'textarea':
      case 'number':
        if (event.target.value.trim() === '') {
          newInputFilled[subIndex] = false;
        } else {
          newInputFilled[subIndex] = true;
          handleChoice(name, event.target.value);
        }
        break;
      default:
        newInputFilled = Array(subtext.length).fill(false);
        newInputFilled[subIndex] = true;
        handleChoice(name, label);
        break;
    }
    setInputFilled(newInputFilled);

  };
  return (
    <ul className="subList">
      {subtext.map((sub, subIndex) => (
        <li key={subIndex} className={inputFilled[subIndex] ? 'active' : 'inactive'}>
          <input
            type={sub.type}
            placeholder={sub.placeholder}
            name={sub.name}
            checked={sub.type === 'checkbox' ? inputFilled[subIndex] : undefined}
            onChange={(event) => handleInputFilled(sub.type, sub.label, sub.name, subIndex, event)}
            value={sub.type === 'text' || sub.type === 'textarea' || sub.type === 'number' ? sub.value : undefined}
          />
          {sub.label && <label>{sub.label}</label>}
          {sub.type === 'textarea' && <textarea name={sub.name} onChange={(event) => handleInputFilled(sub.type, sub.label, sub.name, subIndex, event)} />}
        </li>
      ))}
    </ul>
  );
};

export default SubList;
