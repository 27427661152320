import React, {useState} from 'react';
import './App.module.css';
import Tier from './components/Tiers';
import Chekclist  from './components/Checklist';
import SVG from './components/SVG';
import { sendDataToServer } from './api';
import EmailForm from './components/email';

function App() {
  const [selected, setSelected] = useState(null); //////This is for theb user's tier
  const [type, setType] = useState({}); /////////this is for the user's selections on the forum to generate the svg

  const handleTier = tier => {
    setSelected(tier)
    setType({})
  }

  const handleType = (key, value) => {
    setType(prev => ({ ...prev, [key]: value }));
  };

  async function handleSubmit(event) {
  
    console.log('Form data:', event);
  
    if (!event.name || !event.email || !selected || Object.keys(type).length === 0) {
      alert('Please fill all required fields: Name, Email, Tier Selection, and Type Data.');
      return;
    }
  
    const fullData = {
      name: event.name,
      email: event.email,
      selectedTier: selected,
      message: Object.entries(type).map(([key, value]) => `${key}: ${value}`).join(', ')
    };
  
    try {
      const response = await sendDataToServer(selected, fullData);
      console.log('Response from server:', response);
      if (response.status === 'success') {
        alert('Email sent successfully!');
      } else {
        alert('Failed to send email: ' + response.message);
      }
    } catch (error) {
      console.error('Failed to send data:', error);
      alert('An error occurred while sending the email.');
    }
  }

  return (
    <div className="App">
      <Tier onSelected={handleTier} />
      <Chekclist selectedTier={selected} type={handleType} />
      <SVG selectedTier={selected} type={type} />
      <EmailForm onClickSubmit={handleSubmit} tier={selected} data={type} />
      <a href='https://4ditsolutions.co.za'>
        <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" fill="currentColor" class="bi bi-house-door" viewBox="0 0 16 16" id="home">
          <path d="M8.354 1.146a.5.5 0 0 0-.708 0l-6 6A.5.5 0 0 0 1.5 7.5v7a.5.5 0 0 0 .5.5h4.5a.5.5 0 0 0 .5-.5v-4h2v4a.5.5 0 0 0 .5.5H14a.5.5 0 0 0 .5-.5v-7a.5.5 0 0 0-.146-.354L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293zM2.5 14V7.707l5.5-5.5 5.5 5.5V14H10v-4a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5v4z"/>
        </svg>
      </a>

    </div>
  );
}
export default App;

