import React, {useState} from "react";

const Tier = ({onSelected}) => {
    const tiers = ['Static', 'Responsive', 'Dynamic'];
    const [selectedTier, setSelectedTier] = useState(null);

    const handleTier = (tier) => {
        setSelectedTier(tier);
        onSelected(tier);
    };

    return (<div className="tier-div">
        {tiers.map((tier, i) => {
            return <div 
            key={i}
            className={`tier ${selectedTier === tier ? "tier selected" : "tier"}`}
            onClick={() => handleTier(tier)}
            >
                <h1>{tier}</h1>

            </div>
        })}
    </div>)
}

export default Tier;
