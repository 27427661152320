import React, { useState, useEffect } from 'react';
import circle from './../output-onlinegiftools.gif';
import fetchItems from './../Utils/fetchItems';
import SubList from './SubList';

const Checklist = ({ selectedTier, type }) => {
  const [items, setItems] = useState([]);
  const [checkedItems, setCheckedItems] = useState([]);

  useEffect(() => {
    const newItems = fetchItems(selectedTier);
    setItems(newItems);
    setCheckedItems(newItems.map(() => false));
  }, [selectedTier]);

  const handleCheckboxChange = index => {
    const newCheckedItems = [...checkedItems];
    newCheckedItems[index] = !newCheckedItems[index];
    setCheckedItems(newCheckedItems);
  };

  return (
    selectedTier ? (
      <div>
        <h2>Items for Tier {selectedTier}:</h2>
        <ul className="mainList">
          {items.map((item, index) => (
            <li key={index}>
              <input
                type="checkbox"
                checked={checkedItems[index] || false} // Ensure it's always a boolean
                onChange={() => handleCheckboxChange(index)}
              />
              <span>{item.text}</span>
              {checkedItems[index] && item.subtext.length > 0 && (
                <SubList subtext={item.subtext} choice={type} resetDependency={selectedTier} key={`${selectedTier}-${index}`} />
              )}
            </li>
          ))}
        </ul>
      </div>
    ) : <h1 id="choose-tier"><img src={circle} width='5%' height='2%'/>Please choose a tier...</h1>
  );
};

export default Checklist;
