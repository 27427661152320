const fetchItems = (tier) => {
    switch (tier) {
      case 'Static':
        return [
          { text: 'Do you have an existing website you want us to look at?',
            subtext: [
              {
                type: 'text',
                name: 'website-link',
                placeholder: 'Please provide the link'
              }
            ]
          },
          { text: 'Preferred layout', 
            subtext: [
              {
                type: 'radio',
                name: 'layout',
                label: 'Z-pattern layout'
              },
              {
                type: 'radio',
                name: 'layout',
                label: 'F-pattern layout'
              },
            ] 
          },
          { text: 'How many words will the website contain?',
            subtext: [
              {
                type: 'number',
                name: 'estimsated-number',
                placeholder: 'Give an estimated number'
              },
            ]
          },
          { text: 'Navigation bar',
            subtext: [
              {
                type: 'radio',
                name: 'navbarType',
                label: 'Background colour'
              },
              {
                type: 'radio',
                name: 'navbarType',
                label: 'No Background'
              },
            ]
          },
          { text: 'Preferred colour',
            subtext: [
              {
                type: 'radio',
                name: 'colour',
                label: 'Red'
              },
              {
                type: 'radio',
                name: 'colour',
                label: 'Blue'
              },
              {
                type: 'radio',
                name: 'colour',
                label: 'Yellow'
              },
              {
                type: 'radio',
                name: 'colour',
                label: 'Green'
              },
              {
                type: 'radio',
                name: 'colour',
                label: 'Orange'
              },
              {
                type: 'radio',
                name: 'colour',
                label: 'Purple'
              },
              {
                type: 'radio',
                name: 'colour',
                label: 'Pink'
              },
              {
                type: 'radio',
                name: 'colour',
                label: 'Cyan'
              },
              {
                type: 'radio',
                name: 'colour',
                label: 'Brown'
              },
              {
                type: 'radio',
                name: 'colour',
                label: 'Gold'
              },
            ]
          },
          { text: 'Preferred shade',
            subtext: [
              {
                type: 'radio',
                name: 'Shading',
                label: 'Black'
              },
              {
                type: 'radio',
                name: 'Shading',
                label: 'White'
              },
              {
                type: 'radio',
                name: 'Shading',
                label: 'Grey'
              },
            ]
          },
        ];
      case 'Responsive':
        return [
          { text: 'Do you have an existing website you want us to look at?',
            subtext: [
              {
                type: 'text',
                placeholder: 'PLease provide the link',
                name: 'website-link'
              },
            ]
          },
          { text: 'Preferred layout',
            subtext: [
              {
                type: 'radio',
                name: 'layout',
                label: 'Z-pattern layout'
              },
              {
                type: 'radio',
                name: 'layout',
                label: 'F-pattern layout'
              },
              {
                type: 'radio',
                name: 'layout',
                label: 'Single Column'
              },
              {
                type: 'radio',
                name: 'layout',
                label: 'Cards'
              },
            ] 
          },
          { text: 'How many words will the website contain?',
            subtext: [
              {
                type: 'number',
                name: 'estimsated-number',
                placeholder: 'Give an estimated number'
              },
            ]
          },
          { text: 'Navigation bar',
            subtext: [
              {
                type: 'radio',
                name: 'navbarType',
                label: 'Background colour'
              },
              {
                type: 'radio',
                name: 'navbarType',
                label: 'Background Hue'
              },
              {
                type: 'radio',
                name: 'navbarType',
                label: 'No Background'
              },
              {
                type: 'radio',
                name: 'navbarType',
                label: 'Background Image'
              },
            ] 
          },
          { text: 'Preferred colour scheme',
            subtext: [
              {
                type: 'radio',
                name: 'colour',
                label: 'Monochromatic Shades of Red'
              },
              {
                type: 'radio',
                name: 'colour',
                label: 'Monochromatic Shades of Blue'
              },
              {
                type: 'radio',
                name: 'colour',
                label: 'Monochromatic Shades of Yellow'
              },
              {
                type: 'radio',
                name: 'colour',
                label: 'Monochromatic Shades of Green'
              },
              {
                type: 'radio',
                name: 'colour',
                label: 'Monochromatic Shades of Orange'
              },
              {
                type: 'radio',
                name: 'colour',
                label: 'Monochromatic Shades of Purple'
              },
              {
                type: 'radio',
                name: 'colour',
                label: 'Monochromatic Shades of Pink'
              },
              {
                type: 'radio',
                name: 'colour',
                label: 'Monochromatic Shades of Cyan'
              },
              {
                type: 'radio',
                name: 'colour',
                label: 'Monochromatic Shades of Brown'
              },
              {
                type: 'radio',
                name: 'colour',
                label: 'Monochromatic Shades of Gold'
              },
              {
                type: 'radio',
                name: 'colour',
                label: 'Monochromatic Shades of Black/White'
              },
              {
                type: 'radio',
                name: 'colour',
                label: 'Monochromatic Shades of Grey'
              },
              {
                type: 'radio',
                name: 'colour',
                label: 'Complimentary Shades of Green & Purple'
              },
              {
                type: 'radio',
                name: 'colour',
                label: 'Complimentary Shades of Yellow & Blue'
              },
              {
                type: 'radio',
                name: 'colour',
                label: 'Complimentary Shades of Red & Cyan'
              },
            ]
          },
          { text: 'Preferred shade',
            subtext: [
              {
                type: 'radio',
                name: 'Shading',
                label: 'Dark'
              },
              {
                type: 'radio',
                name: 'Shading',
                label: 'Light'
              },
            ]
          },
          { text: 'Type of media files',
          subtext: [
            {
              type: 'checkbox',
              name: 'media-audio',
              label: 'Audio'
            },
            {
              type: 'checkbox',
              name: 'media-gif',
              label: 'GIF'
            },
            {
              type: 'checkbox',
              name: 'media-video',
              label: 'Video'
            },
            {
              type: 'checkbox',
              name: 'media-image',
              label: 'Image (JPG, JPEG, PNG, etc.)'
            },
            {
              type: 'checkbox',
              name: 'media-pdf',
              label: 'PDF'
            },
          ]
        },
        ];
      case 'Dynamic':
        return [
          { text: 'Do you have an existing website you want us to look at?',
            subtext: [
              {
                type: 'text',
                name: 'website-link',
                placeholder: 'Please provide a link'
              },
            ]
          },
          { text: 'Preferred layout', 
            subtext: [
              {
                type: 'radio',
                name: 'layout',
                label: 'Z-pattern layout'
              },
              {
                type: 'radio',
                name: 'layout',
                label: 'F-pattern layout'
              },
              {
                type: 'radio',
                name: 'layout',
                label: 'Single Column'
              },
              {
                type: 'radio',
                name: 'layout',
                label: 'Cards'
              },
              {
                type: 'radio',
                name: 'layout',
                label: 'Full Screen Image'
              },
              {
                type: 'radio',
                name: 'layout',
                label: 'Split Screen'
              },
            ] 
          },
          { text: 'How many words will the website contain?',
            subtext: [
              {
                type: 'number',
                name: 'estimated-number',
                placeholder: 'Give an estimated number'
              },
            ]
          },
          { text: 'Custom navigation bar?',
            subtext: [
              {
                type: 'radio',
                name: 'navbarType',
                label: 'Solid'
              },
              {
                type: 'radio',
                name: 'navbarType',
                label: 'Seperated'
              },
              {
                type: 'radio',
                name: 'navbarType',
                label: 'Sidebar'
              },
              {
                type: 'radio',
                name: 'navbarType',
                label: 'Footer'
              },
              {
                type: 'radio',
                name: 'navbarType',
                label: 'Button'
              },
            ]
          },
          { text: 'Preferred colour',
            subtext: [
              {
                type: 'radio',
                name: 'colour',
                label: 'Grey'
              },
              {
                type: 'radio',
                name: 'colour',
                label: 'Red'
              },
              {
                type: 'radio',
                name: 'colour',
                label: 'Blue'
              },
              {
                type: 'radio',
                name: 'colour',
                label: 'Yellow'
              },
              {
                type: 'radio',
                name: 'colour',
                label: 'Orange'
              },
              {
                type: 'radio',
                name: 'colour',
                label: 'Green'
              },
              {
                type: 'radio',
                name: 'colour',
                label: 'Purple'
              },
              {
                type: 'radio',
                name: 'colour',
                label: 'Pink'
              },
              {
                type: 'radio',
                name: 'colour',
                label: 'Brown'
              },
              {
                type: 'radio',
                name: 'colour',
                label: 'Cyan'
              },
              {
                type: 'radio',
                name: 'colour',
                label: 'Magenta'
              },
              {
                type: 'radio',
                name: 'colour',
                label: 'Maroon'
              },
              {
                type: 'radio',
                name: 'colour',
                label: 'Teal'
              },
              {
                type: 'radio',
                name: 'colour',
                label: 'Beige'
              },
              {
                type: 'radio',
                name: 'colour',
                label: 'Peach'
              },
              {
                type: 'radio',
                name: 'colour',
                label: 'Olive'
              },
              {
                type: 'radio',
                name: 'colour',
                label: 'Gold'
              },
            ]
          },
          { text: 'Scheme',
            subtext: [
              {
                type: 'radio',
                name: 'Scheme',
                label: 'Solid'
              },
              {
                type: 'radio',
                name: 'Scheme',
                label: 'Monochromatic'
              },
              {
                type: 'radio',
                name: 'Scheme',
                label: 'Triadic'
              },
              {
                type: 'radio',
                name: 'Scheme',
                label: 'Complimentary'
              },
              {
                type: 'radio',
                name: 'Scheme',
                label: 'Analogous'
              },
            ]
          },
          { text: 'Type of media files',
          subtext: [
            {
              type: 'checkbox',
              name: 'media-audio',
              label: 'Audio'
            },
            {
              type: 'checkbox',
              name: 'media-gif',
              label: 'GIF'
            },
            {
              type: 'checkbox',
              name: 'media-video',
              label: 'Video'
            },
            {
              type: 'checkbox',
              name: 'media-image',
              label: 'Image (JPG, JPEG, PNG, etc.)'
            },
            {
              type: 'checkbox',
              name: 'media-pdf',
              label: 'PDF'
            },
          ]
          },
          { text: 'Shading',
            subtext: [
              {
                type: 'radio',
                name: 'Shading',
                label: 'Dark'
              },
              {
                type: 'radio',
                name: 'Shading',
                label: 'Light'
              },
            ]
          },
          { text: 'Scroll direction',
            subtext: [
              {
                type: 'radio',
                name: 'scroll',
                label: 'Horizontal'
              },
              {
                type: 'radio',
                name: 'scroll',
                label: 'Vertical'
              },
            ]
          }
        ];
      default:
        return [];
    }
  };

  export default fetchItems;