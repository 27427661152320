import React, { useState } from 'react';

const EmailForm = ({ onClickSubmit, data, tier }) => {
    const [isModalOpen, setModalOpen] = useState(false);
    const [form, setForm] = useState({ name: '', email: '' });

    const toggleModal = () => {
        setModalOpen(!isModalOpen);
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setForm(prev => ({ ...prev, [name]: value }));
    };

    const handleFormSubmit = () => {
        onClickSubmit(form);
    };

    return (
        <>
            <div id="contactModal" className="modal" style={{ display: isModalOpen ? 'block' : 'none' }}>
                <div className="modal-content">
                    <span className="close" onClick={toggleModal}>×</span>
                    <h2>{tier}</h2>
                    <form id="contactForm">
                        <label htmlFor="name">Name:</label>
                        <input type="text" id="name" name="name" required value={form.name} onChange={handleChange} /><br />
                        <label htmlFor="email">Email:</label><br />
                        <input type="email" id="email" name="email" required value={form.email} onChange={handleChange} /><br />
                        <label htmlFor="message">Your choices</label>
                        <ul id="message" name="message" rows="4">
                            {Object.entries(data).map(([key, value]) => <li key={key}>{value}</li>)}
                        </ul>
                        <p>We will contact you to confirm more information and specifics.</p>
                        <button type="button" onClick={handleFormSubmit}>Submit</button>
                    </form>
                </div>
            </div>
            <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" fill="currentColor" className="bi bi-envelope" viewBox="0 0 16 16" id="envelope" onClick={toggleModal}>
                <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1zm13 2.383-4.708 2.825L15 11.105zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741M1 11.105l4.708-2.897L1 5.383z" />
            </svg>
        </>
    );
}

export default EmailForm;
